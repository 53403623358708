// Variables
@import "variables";
@media (max-width: 1400px) {
    .homepage {
        .header {
            .cardSection {
                .row {
                    height: 100%;
                }
            }
            .carousel-item {
                .item {
                    min-height: 350px;
                    height: 100%;
                }
            }
        }
        .otherBrands {
            .brands {
                .bonusItem {
                    .bonus {
                        .button {
                            padding: 1rem 1rem !important;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .homepage {
        .otherBrands {
            .brands {
                gap: 1rem;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                .bonusItem {
                    .bonus {
                        .button {
                            padding: 1rem 1rem !important;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .homepage {
        .header {
            gap: 1rem;
        }
        .topBrands {
            gap: 1rem;
            .segment {
                .row {
                    gap: 1rem;
                }
            }
        }
        .legal {
            gap: 1rem;
        }
        .listCards {
            .cards {
                ul {
                    gap: 1rem;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }
            }
        }
        .banner {
            .slide {
                .carousel-item {
                    background-size: 100% auto;
                    .item {
                        padding: 3rem 1.5rem 1rem 1.5rem;
                        height: auto;
                        min-height: 500px;
                        .data {
                            .title {
                                padding-right: 100px;
                            }
                        }
                    }
                }
            }
        }
        .otherBrands {
            .brands {
                gap: 1rem;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                .bonusItem {
                    .data {
                        height: 80px;
                    }
                    .bookLogo {
                        img {
                            max-width: 70%;
                        }
                    }
                    .number {
                        left: -1rem;
                        top: -1rem;
                    }
                    .stars {
                        display: none;
                    }
                    .bonus {
                        .button {
                            font-size: $fontSizeSmallText !important;
                            min-width: auto;
                        }
                    }
                    .action {
                        text-align: center;
                        display: block;
                    }
                }
            }
        }
    }
    header {
        .col {
            flex: 1;
        }
        .logoWebsite {
            max-height: 30px;
        }
    }
    .listBonusSmall {
        .bonusItemSmall {
            .data {
                width: 100%;
            }
        }
    }
    .categoriesTitle {
        padding-bottom: 1rem;
    }
    .sidebarNav {
        margin-bottom: 1rem;
    }
    .posts {
        .lastArticles {
            .posts {
                ul {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }
            }
        }
    }
}

@media (max-width: 768px) {
    body {
        padding-top: 70px;
    }
    .only_desktop {
        display: none !important;
    }
    .only_mobile {
        display: block !important;
    }
    .scroll-up {
        .sideBarInfos {
            top: 0px !important;
        }
    }
    .sub-mobile {
        display: none;
    }
    .background {
        opacity: 0.5;
    }
    header {
        height: 70px;
        .barMenu {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
    .socialBtns {
        display: flex;
        flex-direction: column;
    }
    .breadcrumbSection {
        ul {
            li {
                font-size: 12px;
                line-height: 14px;
                display: inline-flex;
                a {
                    text-transform: capitalize;
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                &:after {
                    margin-right: 0px;
                    padding: 0px 4px;
                    display: inline-block;
                }
                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                    vertical-align: middle;
                }
            }
        }
    }
    .homepage {
        padding: 0 2rem;
        .header {
            h1 {
                font-size: 2.2rem;
                strong {
                    font-size: 1.5rem;
                }
            }
            .cardSection.secundary {
                margin-top: 20px;
            }
            .cardSection.bannerSlide {
                .row {
                    flex-direction: column;
                }
            }
        }
        .topBrands {
            .topBonus {
                margin-bottom: 15px;
            }
            &.segments {
                .actions {
                    display: flex;
                    flex-direction: column;
                    column-gap: 0px;
                    gap: 1rem;
                }
            }
            .bonus {
                .bonusItemSmall {
                    .bookLogo {
                        width: 100%;
                        height: 48px;
                        img {
                            width: 65%;
                        }
                    }
                    .image {
                        display: flex;
                        flex: 1;
                        .bookLogo {
                            width: 100% !important;
                        }
                    }
                    .title {
                        margin: 0 !important;
                        font-size: 1.1rem !important;
                        font-weight: 600 !important;
                        color: white !important;
                        line-height: 1rem;
                    }
                    .description {
                        color: $tertiaryColor;
                        font-weight: 500;
                    }
                    .data {
                        display: flex;
                        flex-direction: column;
                        align-content: center;
                        justify-content: center;
                        flex: 1;
                        background: transparent !important;
                        .infosBonus {
                            display: flex;
                            align-content: center;
                            flex-direction: row;
                            gap: 1rem;
                            .data {
                                .row {
                                    display: flex;
                                    flex-direction: column;
                                    .title {
                                        padding-bottom: 0.5rem;
                                    }
                                    .description {
                                        font-size: $fontSizeH3;
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
        .lastArticles {
            display: flex;
            flex-direction: column;
            .listCards {
                .cards {
                    ul {
                        grid-template-columns: repeat(1, minmax(0, 1fr));
                        gap: 1rem;
                    }
                }
            }
        }
        .legal {
            .cardSection {
                .titleSection {
                    max-width: 100%;
                    position: relative;
                }
            }
        }
        .otherBrands {
            .brands {
                gap: 1rem;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                .bonusItem {
                    .data {
                        height: 80px;
                    }
                    .bookLogo {
                        img {
                            max-width: 70%;
                        }
                    }
                    .number {
                        left: -1rem;
                        top: -1rem;
                    }
                    .stars {
                        display: none;
                    }
                    .bonus {
                        .button {
                            font-size: $fontSizeSmallText !important;
                            min-width: auto;
                        }
                    }
                    .action {
                        text-align: center;
                        display: block;
                    }
                }
            }
        }
        .header {
            .slide {
                .carousel-item {
                    .item {
                        height: 220px;
                    }
                }
            }
        }
        .legal {
            .cardSection {
                height: auto;
            }
            .slide {
                min-height: 450px;
                .carousel-item {
                    background-size: 100% auto;
                    .item {
                        padding: 3rem 1.5rem 7rem 1.5rem;
                        height: auto;
                        .icon {
                            display: none;
                        }
                        .data {
                            .title {
                                padding-right: 100px;
                            }
                        }
                    }
                }
            }
        }
        .banner {
            .cardSection {
                height: auto;
            }
            .slide {
                .carousel-item {
                    background-size: 100% auto;
                    .item {
                        padding: 3rem 1.5rem 7rem 1.5rem;
                        height: auto;
                        .data {
                            .title {
                                padding-right: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
    footer {
        .footerGrid {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 0px 2rem 2rem 2rem;
        }
        .footer-bottom {
            padding: 2rem 1rem;
            .logoListLegal {
                text-align: center;
                justify-content: center;
            }
            .legalText {
                text-align: center;
            }
            .siteLogoFooter {
                display: flex;
                flex-direction: column;
            }
            .actionThemeMode {
                align-items: center;
            }
        }
    }
    .posts {
        padding: 0 1rem;
        .sideBarInfos.hideAnchor {
            display: none;
        }
        .categoriesFilters {
            margin-bottom: 0.5rem;
        }
        #sliderBonus {
            margin-top: 20px;
        }
        .listCards {
            .cards {
                ul {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                    .empty {
                        grid-column: 1;
                        padding: 5rem 0px;
                    }
                }
            }
        }
        .sidebar {
            .specs {
                ul {
                    grid-template-columns: repeat(4, minmax(0, 1fr));
                }
            }
        }
        &.detailsArticle {
            .sideBarInfos {
                order: 1;
                position: fixed;
                left: 0px;
                right: 0px;
                padding: 0px;
                top: 68px;
                z-index: 999;
                .sidebarNav {
                    display: inline-block;
                    max-width: calc(100vw);
                    overflow-x: auto;
                    border-radius: 0px;
                    border-left: 0px;
                    border-right: 0px;
                    margin-right: 0px;
                    margin-left: 0px;
                    top: 0px;
                    background: $quaternaryColor;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    #sliderBonus {
                        display: none;
                    }
                    .cardSection {
                        overflow: initial;
                    }
                    ul {
                        display: flex;
                        grid-template-columns: none;
                        justify-content: left;
                        padding-left: 4px;
                        padding-top: 0;
                        padding-bottom: 1px;
                        margin-top: 0 !important;
                        background: transparent;
                        border-radius: 0;
                        li {
                            text-align: center;
                            display: flex;
                            border-bottom: 0px;
                            margin: 0px;
                            padding: 4px;
                            &:last-child {
                                border: 0px;
                            }
                            a {
                                height: 100%;
                                min-width: 200px;
                                font-size: 12px;
                                text-transform: capitalize;
                                display: inline-block;
                                padding: 10px 15px;
                                background: $bgColor;
                                &.active {
                                    background: $mainColor;
                                }
                            }
                        }
                    }
                }
            }
            .headerBanner {
                .cardSection {
                    .row {
                        //flex-direction: column-reverse;
                        flex-direction: column;
                        .bookLogo {
                            height: 200px;
                        }
                    }
                }
                .slide {
                    .arrows {
                        top: 1rem;
                        right: 1rem;
                    }
                }
            }
            .infosPost {
                .summary {
                    ul.listInfosPost {
                        display: grid;
                        grid-template-columns: repeat(2, minmax(0, 1fr));
                        row-gap: 10px;
                    }
                }
            }
            .lastArticles {
                .name {
                    font-size: 1.4rem;
                    align-items: center;
                }
                .posts {
                    ul {
                        grid-template-columns: repeat(1, minmax(0, 1fr));
                    }
                }
            }
        }
    }
    .postCard.cardSection {
        min-height: auto;
        .firstItem {
            flex-direction: column-reverse;
            .imageContent {
                min-height: 200px;
            }
        }
    }
    .glossaries {
        .bannerPost {
            .description {
                margin-top: 10px;
            }
        }
        .title {
            padding: 1rem !important;
        }
        .subtitle {
            h2 {
                font-size: 26px;
                font-weight: 800;
            }
        }
        .filters {
            flex-direction: column;
            align-items: stretch;
            top: 0;
            .search {
                padding-right: 0;
                padding-bottom: 10px;
                input {
                    height: 55px;
                }
            }
            .alphas {
                overflow-x: auto;
                flex: none;
                width: 100%;
                &::-webkit-scrollbar {
                    display: none;
                }
                ul {
                    justify-content: left;
                    flex-wrap: inherit;
                    li {
                        min-width: 42px;
                    }
                }
            }
        }
        .data {
            .group {
                .letter {
                    .pointer {
                        top: -150px;
                    }
                }
                ul {
                    grid-template-columns: none;
                }
            }
        }
    }
    .widget {
        .widget_affiate_list {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            margin-bottom: 20px;
            .bonusItem {
                a {
                    font-size: 0.9rem;
                    padding: 1rem;
                }
                .bonus {
                    .button {
                        color: white;
                    }
                }
                .action {
                    .stars {
                        font-size: 0.8rem;
                        ul {
                            li {
                                i {
                                    font-size: 0.7rem;
                                }
                            }
                        }
                    }
                    a {
                        padding: 0.5rem;
                    }
                }
            }
        }
        .widget_affiliate {
            top: auto !important;
            bottom: 10px;
            z-index: 999;
            padding: 5px 5px;
            column-gap: 0px;
            display: grid;
            flex-direction: row;
            grid-template-columns: 1fr 1fr auto;
            transition: none;
            text-decoration: none !important;
            .logo {
                order: 1;
                height: 45px;
                padding: 0.5rem 0px;
            }
            .title {
                padding: 5px 0.5rem;
                text-align: center;
                font-size: 12px;
                line-height: 16px;
                order: 3;
                grid-column-end: 3;
                grid-column-start: 1;
                height: 42px;
                display: none;
            }
            .affiliateLogo {
                display: grid;
                flex-direction: row;
                align-content: center;
                align-items: center;
                grid-template-columns: 1fr 1fr;
                white-space: nowrap;
                gap: 0;
                .brandName {
                    display: none;
                }
            }
            .bonusMobile {
                display: flex;
                order: 2;
                justify-content: center;
                font-weight: 700;
                font-size: $fontSizeText;
            }
            .action {
                order: 2;
                justify-content: center;
                padding-left: 0.5rem;
                .button {
                    padding: 0.5rem 1.5rem;
                }
            }
            &.last {
                position: fixed;
                width: calc(100vw - 49px);
                margin: 0;
            }
        }
        @for $i from 1 through 30 {
            &.affiliate:nth-of-type(#{$i}),
            &.affiliate:nth-of-type(#{$i}) .widget_affiliate {
                z-index: 999 - $i;
            }
        }
        .widget_text {
            padding: 4rem 2rem;
            border: none;
            .title {
                font-size: $fontSizeH2;
                line-height: 2.6rem;
            }
            &:before {
                top: 15px;
                left: 7px;
                font-size: 4.5rem;
            }
        }
        .widget_payments {
            .infosList {
                flex-direction: column;
                gap: 20px;
                .title {
                    text-align: center;
                    font-size: 1.6rem;
                }
            }
            .listPayments {
                flex-direction: column;
                gap: 20px;
                .sectionList {
                    width: 100%;
                }
            }
        }
    }

    // ODOS
    .odds.details {
        .contentBody {
            width: 100%;
        }
        .dataTeams {
            .banner {
                &.hasPrognostic {
                    padding-bottom: 150px;
                    min-height: auto;
                }
                .header {
                    .titleGame {
                        .titleLeague {
                            padding-bottom: 10px;
                            font-size: $fontSizeTitleLabel;
                            line-height: $fontLHeightTitleLabel;
                        }
                        h1 {
                            font-size: $fontSizeSmallH1;
                            line-height: $fontLHeightSmallH1;
                        }
                    }
                }
                .teams {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    -moz-column-gap: 40px;
                    column-gap: 40px;
                    .data {
                        order: 1;
                        grid-column-start: 1;
                        grid-column-end: 5;
                        .highlight {
                            grid-template-columns: 1fr 40% 1fr;
                            padding-bottom: 20px;
                            margin-bottom: 20px;
                            .team {
                                font-size: 15px;
                                line-height: 18px;
                                img {
                                    height: 74px;
                                    margin-bottom: 10px;
                                }
                            }
                            .draw {
                                justify-content: flex-start;
                                .dateGame {
                                    font-size: 14px;
                                    .date {
                                        font-size: 14px;
                                        margin-bottom: 15px;
                                    }
                                }
                                .result {
                                    font-size: 36px;
                                }
                            }
                        }
                        .odds {
                            grid-template-columns: 1fr 40% 1fr;
                            .item {
                                font-size: $fontSizeH3;
                                span {
                                    font-size: $fontSizeSmallText;
                                    margin-right: 8px;
                                }
                                i {
                                    font-size: $fontSizeH3;
                                    margin-top: 0px;
                                    margin-left: 8px;
                                }
                            }
                        }
                    }
                    .specs {
                        order: 2;
                        &:first-child {
                            grid-column-start: 1;
                            grid-column-end: 3;
                        }
                        &:last-child {
                            grid-column-start: 3;
                            grid-column-end: 5;
                        }
                        ul {
                            li {
                                .key {
                                    font-size: 11px;
                                }
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 10px;
                                align-items: center;
                                .formTeam {
                                    display: flex;
                                    column-gap: 2px;
                                    li {
                                        background: gray;
                                        border-radius: 2px;
                                        height: 16px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 15px;
                                        font-size: 10px;
                                        margin-bottom: 0;
                                        &.type_W {
                                            background: #32b760;
                                        }
                                        &.type_L {
                                            background: #ff0034;
                                        }
                                        &.type_D {
                                            background: #ffc900;
                                            color: black;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .history {
                width: 100%;
            }
        }
    }

    .odds {
        .listOrderMobile {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: stretch;
            justify-content: space-evenly;
            align-items: stretch;
            .sidebar {
                order: 2;
            }
            .data {
                order: 1;
            }
        }

        .wrapper {
            .sidebar.right {
                padding-left: 15px;
            }
            .sidebar.left {
                padding-right: 15px;
            }
        }
        .event {
            width: 100% !important;
            min-height: 100px !important;
            display: block !important;
            box-shadow: 0 3px 6px rgba(black, 0.15);
            grid-template-columns: 70px 1fr 70px !important;
            margin-bottom: 15px;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            padding: 10px 15px;
            font-size: 14px;
            text-align: center;
            &:hover {
                cursor: pointer;
            }
            .date {
                display: block;
                line-height: 18px;
                text-transform: uppercase;
                width: 100%;
                .previsionBet {
                    text-transform: uppercase;
                    font-size: 0.7rem;
                    margin-top: 5px;
                    margin-bottom: -5px;
                    i {
                        margin-right: 5px;
                        color: t($secundaryColor);
                    }
                }
            }
            .data {
                width: 100%;
                display: grid !important;
                grid-template-columns: 1fr 120px 1fr;
                padding-right: 0 !important;
                text-align: center;
                .home {
                    text-align: right;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    .team {
                        justify-content: end;
                    }
                }
                .draw {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                }
                .away {
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                }
                .team {
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                    display: inline-block;
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: flex-start !important;
                    align-content: center;
                    text-align: center;
                    min-height: 70px;
                    .nameTeam {
                        display: flex;
                        order: 2;
                        padding-top: 6px;
                    }
                    .img {
                        min-width: 60px;
                        text-align: center;
                        display: flex;
                        order: 1;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        align-content: center;
                        img {
                            height: 40px;
                        }
                    }
                }
                .result {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 40px;
                    min-height: 70px;
                }
                .oddValue {
                    display: inline-block;
                    color: $mainColor;
                    font-size: 12px;
                    font-weight: bold;
                    padding: 4px 10px;
                    line-height: 14px;
                    margin-top: 15px;
                    min-width: 60px;
                    text-align: center;
                    background: t($secundaryColor);
                }
                .postponed {
                    margin-top: 15px;
                    display: inline-block;
                }
            }
            .bet {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: bold;
                display: flex;
                justify-content: end;
                padding: 1rem 0px 0.5rem 0px;
                justify-content: center;
                a {
                    img {
                        max-height: 20px;
                    }
                }
            }
            .gameResult {
                width: 26px;
                height: 26px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-weight: 700;
                font-size: 16px;
                background: #14dc4b;
                text-align: right;
                &.type_D {
                    background: #dc0000;
                }
            }
        }

        &.details {
            .dataTeams {
                .history {
                    .nav {
                        display: grid;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-content: center;
                        align-items: stretch;
                        justify-items: stretch;
                        grid-template-columns: 1fr 1fr 1fr;
                        li {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-content: center;
                            justify-content: center;
                            align-items: stretch;
                            a {
                                display: flex;
                                align-items: center;
                                padding: 14px 10px;
                                white-space: inherit;
                                text-align: center;
                                font-size: 14px;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
